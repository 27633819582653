<template>
  <div class="faces-container">
    <!-- Filter Section -->
    <div class="accordion" id="facesFilterAccordion">
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingFacesFilter">
          <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#facesFilters"
            aria-expanded="true" aria-controls="facesFilters">
            Filters (Click to Open)
          </button>
        </h2>
        <div id="facesFilters" class="accordion-collapse collapse show" aria-labelledby="headingFacesFilter">
          <div class="accordion-body">
            <div class="row">
              <!-- Start Date Filter -->
              <div class="col-md-3 col-sm-12">
                <label for="startDate">Start Date</label>
                <input type="date" v-model="startDate" id="startDate" class="form-control" />
              </div>
              <!-- Start Time Filter -->
              <div class="col-md-3 col-sm-12">
                <label for="startTime">Start Time</label>
                <input type="time" v-model="startTime" id="startTime" class="form-control" />
              </div>
              <!-- End Date Filter -->
              <div class="col-md-3 col-sm-12">
                <label for="endDate">End Date</label>
                <input type="date" v-model="endDate" id="endDate" class="form-control" />
              </div>
              <!-- End Time Filter -->
              <div class="col-md-3 col-sm-12">
                <label for="endTime">End Time</label>
                <input type="time" v-model="endTime" id="endTime" class="form-control" />
              </div>
              <!-- Site Filter Dropdown -->
              <div class="col-md-4 col-sm-12">
                <label for="siteFilter">Site Filter</label>
                <Multiselect
                    v-model="selectedSite"
                    :options="sites"
                    :multiple="false"
                    :closeOnSelect="true"
                    :clearOnSelect="false"
                    :hideSelected="true"
                    :preserveSearch="true"
                    placeholder="Select Site"
                    label="name"
                    track-by="id"
                    @change="onSiteChange"
                  ></Multiselect>
              </div>
            </div>

            <div class="d-flex justify-content-between my-3">
              <button :disabled="loading" class="btn btn-primary btn-sm flex-grow-1 me-2" @click="fetchFaces(1)">Search</button>

              <!-- Clear Filters Button -->
              <button class="btn btn-primary btn-sm flex-grow-1" @click="clearFilters">Clear Filters</button>
            </div>
          </div>
        </div>
      </div>

      <!-- Button to Create New Incident -->
      <!-- <button class="btn btn-success mt-3" @click="createNewIncident">
        Create New Incident
      </button> -->

      <br/>

      <Pagination :config="pagination" :showTotal="true" @search="fetchFaces" />

      <div class="row">
        <div class="col-12">
          <button class="btn btn-sm burger btn-primary" @click="toggleAllImages()"><i v-show="showing === 'background'" class="material-icons">face</i><i v-show="showing === 'face'" class="material-icons">image</i></button>
          <span style="margin-left: 10px;">Toggle All Images</span>
        </div>
      </div>

      <!-- Faces Display Section -->
      <div class="faces-gallery mt-4 mb-4">
        <div v-show="!loading" v-for="face in faces" :key="face.id" class="face-item">
          <div class="image-container" @contextmenu.prevent>
            <button class="btn btn-sm burger btn-primary" @click="toggleImage(face)"><i v-show="face.showing === 'background'" class="material-icons">face</i><i v-show="face.showing === 'face'" class="material-icons">image</i></button>
            <img v-viewer v-show="face.showing === 'face'" :src="face.images.head.sas" 
            alt="Face Image" class="face-image">
            <img v-viewer v-show="face.showing === 'background'" :src="face.images.background.sas" alt="Face Image" class="face-image">
          </div>
          <div class="face-details">
            <p><strong>Date and Time:</strong> {{ face.created_at_formatted }}</p>
            <p><strong>Age Range:</strong> {{ face.age_range }} | <strong>Quality:</strong> {{ face.quality.toLocaleString() }}</p>
            <!-- Button for finding similiar faces currently not in use -->
            <!-- <button class="btn btn-primary" @click="findSimilarFaces(selectedFace)">Find Similar Faces</button> -->
            <button class="btn btn-danger" @click="reportIncident(face)">Report Incident</button>
          </div>
        </div>
        <div class="text-center">
          <p v-if="!loading && faces.length < 1">No faces found</p>
          <div class="wrapper" v-if="loading">
            <div class="sub-wrapper">
              <Loader/>
            </div>
          </div>
          
        </div>
      </div>

      <!-- PAGINATION -->
      <Pagination :config="pagination" :showTotal="true" @search="fetchFaces" />

    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Pagination from '../../components/Pagination.vue';
import ClientService from '../../services/client.service.js';
import authHeader from "../../services/auth-header";
import Loader from "../dashboard/Customised/Loader.vue";
import { DateTime } from 'luxon';
import { Multiselect } from 'vue-multiselect';
export default {
  name: "Faces",
  components: {
    Pagination,
    Loader,
    Multiselect
  },
  data() {
    return {
      faces: [],
      selectedFace: null,
      pagination: {
        page: 1,
        showing: 24,
        maxPage: 1,
        total: 0
      },
      dateFilter: "",
      faceIdFilter: "",
      cameraIdFilter: "",
      page: "",
      count: "",
      startFilter: "",
      endFilter: "",
      startDate: null,
      endDate: null,
      startTime: null,
      endTime: null,
      isCardExpanded: false,
      loading: false,
      selectedSite: null,
      sites: [],
      showing: "background"
    };
  },

  async created() {
    await this.loadSites();
  },

  methods: {
    fetchFaces(page = 1, siteId = this.selectedSiteId) {
      console.log("Fetch Faces", page);
      if (!siteId) {
        console.error("No site selected");
        return;
      }
      this.loading = true;
      // Update current page
      this.pagination.page = page;

      const params = {
        camera_id: this.cameraIdFilter,
        site_id: siteId, // Compulsory
        face_id: this.faceIdFilter,
        start: this.startFilter,   // Assuming you have a startFilter data property for start date
        end: this.endFilter,
        page: this.pagination.page,
        count: this.pagination.showing,
        start_time: this.startTime,
        end_time: this.endTime,
        zone: DateTime.local().zoneName
      };

      console.log(`Requested page: ${page}`);

      // Construct start and end date-time strings
      if (this.startDate && this.endDate) {
        const formattedStartDate = new Date(this.startDate);
        const formattedEndDate = new Date(this.endDate);

        const startYear = formattedStartDate.getFullYear();
        const startMonth = String(formattedStartDate.getMonth() + 1).padStart(2, '0');
        const startDay = String(formattedStartDate.getDate()).padStart(2, '0');
        const endYear = formattedEndDate.getFullYear();
        const endMonth = String(formattedEndDate.getMonth() + 1).padStart(2, '0');
        const endDay = String(formattedEndDate.getDate()).padStart(2, '0');

        params.start = `${startYear}-${startMonth}-${startDay}`;
        params.end = `${endYear}-${endMonth}-${endDay}`;

        console.log(params.start);
        console.log(params.end);
      }

      // Prepare custom headers
      // const headers = {
      //   'x-vars-claims': '{"id":4,"permissions":["faces.read", "sites.read", "events-blacklist", "cameras-edit", "cameras-view", "sites-map", "logs-view", "staging-manage","users-edit", "claims-process","claims-view", "clients-edit", "accounting-view", "roles-view", "roles-edit", "permissions-view", "sites-view", "reports-view", "reports-claims-view", "nmop-view", "driveoff-view", "cnaf-view", "parking-view", "scheduler-view", "scheduler-delete", "tablets-view", "scheduler-create", "calls-view", "claims-manage-team"]}',
      // };
    
      Object.keys(params).forEach((key) => {
        if (!params[key]) {
          delete params[key];
        }
      });

      axios.get("https://api.varsanpr.com/customer/face/faces", {
        params,
        headers: authHeader()
      })
      .then(response => {
        if (response.status === 200 && siteId === this.selectedSiteId) {
          response.data.faces.forEach((face) => {
            face.showing = 'background';
            face.created_at_formatted = this.formatDateTime(face.created_at);
            face.age_range = this.formatAgeRange(face.age.min, face.age.max);
          })
          this.faces = response.data.faces;
          this.pagination.total = response.data.total;
          this.pagination.maxPage = Math.ceil(response.data.total / this.pagination.showing);

          // Scroll to the top of the page
          window.scrollTo(0, 0);

        }
        this.loading = false;
      })
      .catch(err => {
        console.error('Error fetching faces:', error);
        this.loading = false;
      });
    },

    formatDateTime(timestamp) {
      const date = new Date(timestamp * 1000); // Convert to milliseconds
      return date.toLocaleDateString('en-GB') + ' ' + date.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' });
    },

    onPageChange(newPage) {
      this.fetchFaces(newPage);
    },

    formatAgeRange(min, max) {
      return `${min} - ${max}`;
    },

    findSimilarFaces(selectedFace) {
      // Implement the logic to find similar faces based on the selected face
      // You can make an API call or perform any necessary actions here
      console.log("Finding similar faces for:", selectedFace);
    },

    reportIncident(face) {
      console.log("Reporting incident for face ID:", face.id, "created at:", face.created_at);
      // Navigate to the PersonForm component with the selected face ID
      this.$router.push({
        name: 'NewIncident', // Make sure this name matches your router configuration
        // params: { faceId: face.id, page: this.pagination.page, dateFilter: face.created_at }
        params: { faceId: face.id, created_at: face.created_at, faceSas: face.images.head.sas, backgroundSas: face.images.background.sas, site_id: face.site_id}
      });
    },

    createNewIncident() {
      this.$router.push('/face-incident-form');
    },

    clearFilters() {
      // Reset filter values to their initial state
      this.startDate = null;
      this.endDate = null;
      this.startTime = null;
      this.endTime = null;

      // Fetch data with cleared filters
      this.fetchFaces(1);
    },

    toggleImage(face) {
      if(face.showing === 'face') {
        face.showing = 'background';
      } else {
        face.showing = 'face';
      }
    },

    toggleAllImages() {
      if(this.showing === 'face') {
        this.showing = 'background';
      } else {
        this.showing = 'face';
      }

      this.faces.forEach(face => {
        face.showing = this.showing;
      });
    },

    getFaceItemClass(face) {
      if (this.selectedFace === face) {
        return 'face-item-expanded';
      } else {
        return 'face-item';
      }
    },

    async loadSites() {
      try {
        const response = await ClientService.getClientSites(this.$store.state.auth.user.selectedClient, null, null, 5);
        this.sites = response.data.sort((a, b) => {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        });
        console.log(this.sites);
        // Iterate through sites to find a working one

        if (this.sites.length > 0) {
          this.selectedSiteId = this.sites[0].id;
          this.selectedSiteId = this.selectedSiteId;
          localStorage.setItem('selectedSiteId', this.selectedSiteId);
          await this.fetchFaces(1, this.selectedSiteId);
        }else{
          this.loading = false;
        }

      } catch (error) {
        console.error('Error loading sites:', error);
        this.loading = false;
      }
    },

    async testSite(siteId) {
      try {
        const response = await axios.get("https://api.varsanpr.com/customer/faces/faces", {
          params: {
            site_id: siteId,
            page: 1,
            count: this.pagination.pageSize
          },
          headers: authHeader()
        });

        // Check if the response is successful and faces data is available
        if (response.status === 200 && response.data.faces && response.data.faces.length > 0) {
          return true; // Site is working and has faces data
        }
        return false; // Site may not have faces data or not working
      } catch (error) {
        console.error(`Error testing site ${siteId}:`, error);
        return false; // Site is not working
      }
    },

    onSiteChange() {
      this.fetchFaces(1);
      this.selectedSiteId = this.selectedSiteId;
      localStorage.setItem('selectedSiteId', this.selectedSiteId);
    },
  },
  computed: {
    selectedSiteId: {
      get() {
        return this.selectedSite ? this.selectedSite.id : null;
      },
      set(value) {
        this.selectedSite = this.sites.find(site => site.id === value);
      }
    }
  }
};
</script>

<style scoped>
.faces-gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: flex-start;
  /* Align items at the start of the cross axis */
}

.face-item,
.face-item-expanded {
  margin: 10px;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 4px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-self: start;
  /* Prevent stretching to match height of expanded items */
}

.face-item {
  width: 400px;
}

.face-item-expanded {
  width: 400px;
  /* You can adjust the width to your preference */
  /* You can adjust the padding to create more space */
}

.face-image {
  width: 100%;
  height: 250px;
  object-fit: cover;
  margin: auto;
}

.face-details {
  margin-top: 10px;
}

.btn-success {
  background-color: #28a745;
  color: white;
}

.btn-success:hover {
  background-color: #218838;
}

.face-item-expanded .face-image {
  width: 100%;
  /* Larger size for expanded image */
  height: auto;
  /* Larger size for expanded image */
  border-radius: 10px;
  /* Removes the round shape to make it a box */
}

/* Ensuring the expanded class has a larger image size than the default item */
.face-item .face-image {
  width: 100%;
  /* Keep the original size */
  height: 250px;
  /* Keep the original size */
}

.image-container {
  position: relative;
}

.image-container button {
  position: absolute;
  top: 5px;
  left: 5px;
}

.burger {
  width: 35px;
  height: 35px;
  line-height: 35px !important;
  position: relative;
  overflow: hidden;
  z-index: 1;
  padding: 0;
  border-radius: 0;
  vertical-align: middle !important;
  border-radius: 5px;
}

.burger i {
  vertical-align: middle !important;
  line-height: 35px !important;
  margin: 0 !important;
  font-size: 24px !important;
}

.wrapper {
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 60vh;
}

.sub-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
